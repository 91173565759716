$(() => {
  let segments = document.querySelector('#page-header > div.content-header > .obis-site-header-segmentscomponent > .segment-container');
  if (!segments) {
    return;
  }

  function updateAndStoreSegmentsHeight() {
    $(segments).children().each((index, elem) => {
      //* we use the elem.scrollHeight property here to avoid showing the element just to calculate it's height
      //* as it's not hidden entirely but just has zero height, the "scrollHeight" equals the exact height if shown fully on page
      $(elem).css('--obis-site-header-segments-text-wrapper-height', ($(elem).find('.text-wrapper').get(0).scrollHeight+'px'));
    });
  }
  
  let throttle;
  $(window).on('resize', () => {
    if (throttle) clearTimeout(throttle);
    throttle = setTimeout(updateAndStoreSegmentsHeight, 100);
  });

  updateAndStoreSegmentsHeight();
});
